
/* BUTTONS */

button {
    font-family: 'Montserrat', serif;
    font-size: var(--main-text-size);
    font-weight: bold;
    border-radius: var(--border-radius);
    padding: 10px 15px;
    cursor: pointer;
    outline: none;
    border: 0;
}

button:disabled {
    cursor: default;
    opacity: .3;
}


.button-main {
    background-color: var(--url-color);
    color: #FFFFFF;
}

.button-black {
    background-color: #FFFFFF;
    border: 2px solid var(--text-color);
    color: var(--text-color);
}

.button-red {
    background-color: var(--url-color);
    color: #FFFFFF;
    border: #FFFFFF 2px solid;
    border-radius: 4px;
}

.button-clean {
    border: var(--url-color) 1px solid;
    background-color: #FFFFFF;
    color: var(--url-color);
}

.telegram {
    background: #26A3E1;
    color: #FFF;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
}

.viber {
    background: #7360F2;
    color: #FFF;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
}

.button-round {
    width: 44px;
    height: 44px;
    background-color: var(--url-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: unset;
}

.button-link {
    padding: 0;
    border: unset;
    background-color: unset;
    color: var(--url-color);
    font-weight: normal;
    text-decoration: underline;
}

/* INPUT CONTROLS */

input, select, textarea {
    height: 2rem;
    padding: 5px 25px 5px 15px;
    font-size: var(--main-text-size);
    outline: none;
    border: #FFFFFF 1px solid;
    background-color: #ffffff;
    color: var(--text-color);
    -ms-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

select {
    opacity: .8;
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-size: 8px;
    background-position: calc(100% - 12px);
    background-color: #ffffff;
    overflow: hidden;

}

textarea {
    resize: none;
    max-width: 400px;
    width: 100%;
}

input::placeholder, textarea::placeholder {
    font-size: 12px;
    color: var(--text-inactive-color);
}

/* SEARCH CONTROLS */

.input-search-mobile-container {
    position: absolute;
    top: 80px;
    left: 5%;
    right: 5%;
}

.input-search-container {
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    border: #CCC 1px solid;
    opacity: 1;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 6px 15px;
}

.input-search, .select-search {
    height: unset;
    padding: 0;
    outline: none;
    border: unset;
    background-color: unset;
    color: var(--text-color);
    -ms-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    box-shadow: unset;
}
