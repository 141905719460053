
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/bebasneueregular.woff2') format('woff2'),
         url('../../assets/fonts/bebasneueregular.woff') format('woff'),
         url('../../assets/fonts/bebasneueregular.ttf')  format('truetype');
}

@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/bebasneuebold.woff2') format('woff2'),
         url('../../assets/fonts/bebasneuebold.woff') format('woff'),
         url('../../assets/fonts/bebasneuebold.ttf')  format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: local('Merriweather'), url('../../assets/fonts/Merriweather-Regular.ttf');
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: local('Merriweather'), url(../../assets/fonts/Merriweather-Bold.ttf);
}

* { margin: 0; padding: 0; }

:root {
    --url-color: #FF5959;
    --text-color: #303030;
    --text-inactive-color: #CCCCCC;
    --separator-color: #C4C4C4;
    --bg-color-main: #FFF;
    --bg-color-secondary: #E7F8FF;
    --bg-color-third: #E5E5E5;

    --heading-text-size: 42px;
    --section-heading-text-size: 34px;
    --main-text-size: 15px;
    --note-text-size: 12px;

    --main-padding: 20px;
    --half-padding: 15px;
    --border-radius: 10px;
}

html {
    overflow-x: hidden;
}

body {
    background-color: var(--bg-color-main);
    font-family: 'Merriweather', serif;
    color: var(--text-color);
    line-height: 1.5;
    height: 100%;
    overflow-x: hidden !important;
    width: 100%;
    position: fixed;
}

h1, h2, h3 { font-weight: 700; line-height: 1.2; }

h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: var(--heading-text-size);
}
h2 {
    font-family: 'Bebas Neue', cursive;
    font-size: var(--section-heading-text-size);
}


p {
    font-family: 'Merriweather', serif;
    font-size: var(--main-text-size);
    line-height: 1.5;
}

span {
    font-family: 'Montserrat', serif;
    font-size: var(--note-text-size);
}

a, .url {
    color: var(--url-color);
    text-decoration: underline;
    font-family: 'Montserrat', serif;
    font-size: 12px;
}

.link {
    color: #000;
    font-family: 'Montserrat', serif;
    font-weight: 400;
    font-size: 12px;
}

div, p {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* UTILS */

.separator {
    height: 1px;
    border-bottom: var(--separator-color) 1px solid;
}

.shadow { box-shadow: 0 0 10px rgba(0,0,0,0.05); }

.disabled { cursor: default; }

.disabledClick {
    cursor: default;
    pointer-events: none;
    opacity: .3;
}

.enabledClick {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

/* LAYOUT */

.app-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden !important;
    width: 100%;
    height: 100%;
    position: fixed;
}

.app-centered-container {
    width: 100%;
    max-width: 1152px;
    padding: var(--main-padding);
    flex-direction: column;
    align-items: center;
}

.app-full-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-red-block {
    background: var(--url-color);
    z-index: -1;
    top: 250px;
    left: 0;
    width: 100%;
    padding: var(--main-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    padding-bottom: 150px;
}

.app-red-block-bg {
    background: var(--url-color);
}

.app-section {
    display: flex;
    flex-direction: row;
}

.app-section-block {
   flex-basis: 50%;
    min-width: 250px;
}

.app-section-block-right {
    position: absolute;
    right: 0;
}

.app-section-block-left {
    position: absolute;
    left: 0;
}

/*
/ FAQ
 */

.faq-container-red {
    background: var(--url-color);
    color: #FFF;
    padding: 10px 15px;
    font-family: 'Montserrat', serif;
    font-size: 14px;
    font-weight: bold;
}

.faq-container-selected {
    color: #000;
    padding: 10px 15px;
    font-family: 'Montserrat', serif;
    font-size: 14px;
    font-weight: bold;
}

/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:600px) {

    .app-section {
        flex-direction: column;
    }
}

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --heading-text-size: 38px;
        --section-heading-text-size: 30px;
        --main-text-size: 14px;
        --note-text-size: 12px;
        --main-padding: 20px;
        --half-padding: 10px;
    }
    body, p {
        line-height: 1.4;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --heading-text-size: 34px;
        --section-heading-text-size: 28px;
        --main-text-size: 13px;
        --note-text-size: 11px;
        --main-padding: 20px;
        --half-padding: 8px;
    }
    body, p {
        line-height: 1.3;
    }

}
